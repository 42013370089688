import React, { useContext } from "react";
import { graphql } from "gatsby";
import { Seo } from "components/contentful/seo/Seo";
import { Hero } from "components/contentful/hero/Hero";
import { MediaTextContentful } from "components/contentful/media-text/MediaText";
import { InfoListContentful } from "components/contentful/info-list/InfoList";
import { BgGradient } from "components/bg-svgs/gradients/BgGradient";
import { WrapperBgs } from "components/container/WrapperBgs";
import { UIContext } from "context/ui";
import gsap from "gsap";
import DrawSVGPlugin from "../utils/DrawSVGPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitText from "../utils/SplitText/SplitText";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { VideoItem } from "components/video-item/VideoItem";
import { QuoteCarousel } from "components/quote-carousel/QuoteCarousel";
import { Line1 } from "components/bg-svgs/lines/aurora-driver/line1/Line1";
import { TextFeaturesContentful } from "components/contentful/text-features/TextFeatures";
import { BillboardTextContentful } from "components/contentful/billboard-text/BillboardText";
import { Line2 } from "components/bg-svgs/lines/aurora-driver/line2/Line2";
import { Line3 } from "components/bg-svgs/lines/aurora-driver/line3/Line3";
import { Line4 } from "components/bg-svgs/lines/aurora-driver/line4/Line4";
import { Line5 } from "components/bg-svgs/lines/aurora-driver/line5/Line5";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
gsap.registerPlugin(CSSRulePlugin);

export default ({ data }: any) => {
  const { isMobile, isDesktop } = useContext(UIContext);
  const { contentfulData } = data;
  const seo = contentfulData.seo;
  return (
    <>
      <BgGradient />
      {seo && <Seo seo={seo} />}
      <WrapperBgs overflowMobile={true}>
        <Hero
          clearPaddingBottom={true}
          hero={{ ...contentfulData.hero, clearPaddingBottom: true }}
        />
        <VideoItem item={contentfulData.body[0]} />
        {isDesktop && <Line1 />}
      </WrapperBgs>

      <WrapperBgs
        style={{
          marginTop: isDesktop ? 80 : 40,
          marginBottom: isDesktop ? 250 : 50
        }}
      >
        <InfoListContentful
          infoList={contentfulData.body[1]}
          titlePlacement="inside"
          titleAsH3={true}
          transitionSimple={true}
          largeVideos={true}
          evenColumns={true}
          expandedOnMobile={true}
          titleAlignTop={true}
        />
        {isDesktop && <Line2 />}
      </WrapperBgs>

      <WrapperBgs>
        <BillboardTextContentful billboardText={contentfulData.body[3]} />
        {isDesktop && <Line3 />}
      </WrapperBgs>

      <WrapperBgs>
        <TextFeaturesContentful
          textFeatures={contentfulData.body[2]}
          wide={true}
        />
      </WrapperBgs>

      <WrapperBgs>
        <InfoListContentful
          infoList={contentfulData.body[4]}
          titlePlacement="inside"
          titleAsH3={true}
          transitionSimple={true}
          largeVideos={true}
          evenColumns={true}
          smallVideos={true}
        />
      </WrapperBgs>

      <WrapperBgs>
        <BillboardTextContentful billboardText={contentfulData.body[5]} />
        {isDesktop && <Line3 />}
      </WrapperBgs>

      <WrapperBgs>
        <InfoListContentful
          style={{
            marginBottom: isDesktop ? 20 : 10
          }}
          titlePlacement="inside"
          borderRadius="top"
          infoList={contentfulData.body[6]}
          imageAlignment="left"
          expandedOnMobile={true}
          titleAlignTop={true}
          evenColumns={true}
          smallVideos={true}
        />
        {isDesktop && <Line4 />}
      </WrapperBgs>

      <WrapperBgs>
        <InfoListContentful
          style={{
            marginBottom: isDesktop ? 20 : 10
          }}
          titlePlacement="inside"
          borderRadius="top"
          infoList={contentfulData.body[7]}
          imageAlignment="right"
          expandedOnMobile={true}
          titleAlignTop={true}
          evenColumns={true}
          smallVideos={true}
        />
      </WrapperBgs>
      <WrapperBgs>
        <InfoListContentful
          style={{
            marginBottom: isDesktop ? 20 : 10
          }}
          titlePlacement="inside"
          borderRadius="top"
          infoList={contentfulData.body[8]}
          imageAlignment="left"
          expandedOnMobile={true}
          titleAlignTop={true}
          evenColumns={true}
          smallVideos={true}
        />

        <InfoListContentful
          style={{
            paddingBottom:0
          }}
          titlePlacement="inside"
          borderRadius="top"
          infoList={contentfulData.body[9]}
          imageAlignment="right"
          expandedOnMobile={true}
          titleAlignTop={true}
          evenColumns={true}
          smallVideos={true}
        />

        {/* <VideoItem style={{marginTop: isDesktop ? 200 : 100}} item={contentfulData.body[10]}/> */}

        {isDesktop && <Line5 />}
      </WrapperBgs>

      <WrapperBgs
        style={{
          marginTop: isDesktop ? 200 : 100
        }}
      >
        <MediaTextContentful
          mediaText={contentfulData.body[11]}
          layout="column"
        />
      </WrapperBgs>
    </>
  );
};

export const query = graphql`
  {
    contentfulData: contentfulPage(name: { eq: "Aurora Driver - v2" }) {
      seo {
        description
        title
        twitterImage {
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
          description
        }
        facebookImage {
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
          description
        }
        no_index
        no_follow
        name
      }
      name
      body {
        ... on ContentfulBillboard {
          id
          title
          text {
            raw
          }
        }
        ... on ContentfulInfoList {
          id
          title
          cta {
            href
            label
          }
          description {
            raw
          }
          items {
            image {
              description
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            title
            text {
              raw
            }
            video {
              file {
                url
              }
              description
            }
          }
          subtitle
        }
        ... on ContentfulList {
          id
          title
          text {
            raw
          }
          cta {
            label
            href
          }
          items {
            title
            text {
              raw
            }
            autoplay
            showPlayButton
            videoUrl
            quote {
              author
              text {
                raw
              }
              cta {
                label
                href
              }
              avatar {
                description
                file {
                  details {
                    image {
                      width
                      height
                    }
                  }
                  url
                }
              }
            }
            image {
              gatsbyImageData
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
              description
            }
            video {
              description
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
          }
          image {
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
            description
          }
        }
        ... on ContentfulQuote {
          id
          author
          text {
            raw
          }
        }
        ... on ContentfulMediaText {
          id
          videoUrl
          autoplay
          showPlayButton
          sectionBackground {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            description
          }
          video {
            description
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
          title
          text {
            raw
          }
          image {
            gatsbyImageData
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            description
          }
          cta {
            href
            label
          }
          imageMobile {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        ... on ContentfulVideo {
          id
          videoUrl
          video {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        ... on ContentfulTextFeatures {
          title
          text {
            raw
          }

          items {
            title
            icon {
              description
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            text {
              raw
            }
          }
        }
      }
      hero {
        title
        text {
          raw
        }
      }
    }
  }
`;
