import React, { useRef, useEffect, useContext } from 'react';
import gsap from 'gsap';

import { Container } from 'components/container/Container';
import { RichText } from 'components/contentful/rich-text/RichText';

import { PrismicImage } from 'types/prismicImage';
import { PrismicRichText } from 'types/prismicRichText';

import { scroll } from 'constants/Scroll'

import { UIContext } from 'context/ui';

import s from './TextFeatures.scss';

interface IProps {
  title?: string;
  text: PrismicRichText;
  features?: Array<IFeatureProps> | null;
  target?: string;
  extraIndent?: boolean;
  layout?: 'list' | 'grid';
}

interface IFeatureProps {
  icon?: PrismicImage;
  title?: string;
  richtext?: PrismicRichText;
}

export const TextFeaturesContentful = ({
  textFeatures,
  target,
  extraIndent=false,
  layout='list',
  wide=false
}: any) => {
  const layoutGrid = layout==='grid' ? true :  false

  const title = textFeatures.title
  const text = textFeatures.text
  const items = textFeatures.items

  const content = items?.map((item: any, index: number) => {

    const icon = item.icon
    const iconDescription = item.iconDescription
    const itemTitle = item.title
    const itemText = item.text


    return (
      <li key={index} className={`${s.textFeatures__feature} | js-t-list`}>
        <figure className={`${s.textFeatures__feature__icon} | js-l-img`}>
          <img
            src={icon.file.url}
            alt={iconDescription}
          />
        </figure>
        <div className={`${s.textFeatures__feature__content} | js-l-info`}>
          {itemTitle && (
            <div className={s.textFeatures__feature__title}>
              <h2>{itemTitle}</h2>
            </div>
          )}
          {itemText && (
            <div className={s.textFeatures__feature__text}>
              <RichText richText={itemText}/>
            </div>
          )}
        </div>
      </li>
    );
  });

  const { isMobile, isDesktop } = useContext(UIContext);
  const featuresRef = useRef<HTMLUListElement>(null);
  let tl:GSAPAnimation;

  useEffect(() => {
    if (featuresRef.current) {
      if (isDesktop) {
        setTimeout(() => {
          const listItemImages = featuresRef.current.querySelectorAll('.js-l-img');
          const listItemDescriptions = featuresRef.current.querySelectorAll('.js-l-info');

          tl = gsap.timeline({
            scrollTrigger: {
              scroller: scroll.container,
              trigger: featuresRef.current,
            }
          });

          tl
            .addLabel('start')
            .set(listItemImages, { scale: 0.7 })
            .to(listItemImages, { duration: 0.6, ease: 'Power3.easeInOut', stagger: 0.3, autoAlpha: 1, scale: 1 }, 'start')
            .to(listItemDescriptions, { duration: 0.6, stagger: 0.3, ease: 'Power3.easeInOut', autoAlpha: 1 }, 'start+=0.1')
          }, 1500)
      }

      if (isMobile) {
        setTimeout(() => {
          const listItems = featuresRef.current.querySelectorAll('.js-t-list');

          listItems.forEach((item) => {
          const image = item.querySelector('.js-l-img');
          const description = item.querySelector('.js-l-info');

          if (image && description) {
            gsap.fromTo(
              image,
              { autoAlpha: 0, scale: 0.7 },
              {
                scrollTrigger: image,
                duration: 0.6,
                ease: 'Power3.easeInOut',
                autoAlpha: 1,
                scale: 1
              }
            );

            gsap.fromTo(
              description,
              { autoAlpha: 0 },
              {
                scrollTrigger: description,
                duration: 0.6,
                ease: 'Power3.easeInOut',
                autoAlpha: 1,
              }
            );
          }
        });
        }, 1500)
      }
    }
  }, [isMobile, isDesktop])

  useEffect( ():any => {
    return () => tl && tl.kill()
  },[])

  return (
    <div className={s('textFeatures', {extraIndent, layoutGrid, wide })} data-target={target}>
      <Container>

        <div className={s.textFeatures__row}>
          <div className={s.textFeatures__row__col}>
            {title && <h2 className={s.textFeatures__title}>{title}</h2>}
            {text && <div className={s.textFeatures__text}><RichText richText={text}/></div>}
          </div>
          <div className={s('textFeatures__row__col', 'right')}>
            <ul ref={featuresRef} className={s.textFeatures__featuresList}>
              {content}
            </ul>
          </div>
        </div>

      </Container>
    </div>
  );
};
