import React, { useEffect, useRef } from "react";
import s from "./Line4.scss";

import gsap from "gsap";
import DrawSVGPlugin from "utils/DrawSVGPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll";

export const Line4 = () => {
  const lineRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      lineRef.current,
      {
        drawSVG: "0%"
      },
      {
        duration: 4.5,
        delay: 2.5,
        drawSVG: "100%",
        ease: "linear",
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          scrub: 1,
          start: "top 88%",
          end: "-=0px"
        }
      }
    );
  }, []);

  return (
    <svg
      className={s.line}
      xmlns="http://www.w3.org/2000/svg"
      width="1254"
      height="363"
      viewBox="0 0 1254 363"
      fill="none"
    >
      <path
        ref={lineRef}
        d="M1253.5 361H140.5C99.5 361 1.5 365 1.5 273C1.5 198.2 1.5 55.1667 1.5 0.5"
        stroke="#1B52AD"
        opacity="0.2"
        strokeWidth="2"
      />
    </svg>
  );
};
