import React, { useEffect, useRef } from "react";
import s from "./Line5.scss";

import gsap from "gsap";
import DrawSVGPlugin from "utils/DrawSVGPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll";

export const Line5 = () => {
  const lineRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      lineRef.current,
      {
        drawSVG: "0%"
      },
      {
        duration: 4.5,
        delay: 2.5,
        drawSVG: "100%",
        ease: "linear",
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          scrub: 1,
          start: "top bottom",
          end: "-=100px"
        }
      }
    );
  }, []);

  return (
    <svg
      className={s.line}
      width="1480"
      height="1950"
      viewBox="0 0 1480 1950"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        ref={lineRef}
        opacity="0.2"
        d="M-123.907 436.193V174.088C-123.907 79.0009 -19.8912 1.91701 108.419 1.91701M1476.56 435.551V173.447C1476.56 78.3591 1372.54 1.2753 1244.23 1.2753M1244.23 1.91288H108.419M1245.95 1948.09H110.14M-123.047 436.167V1513.83M1477.42 436.167V1513.83M1478.28 1513.83V1775.94C1478.28 1871.03 1374.26 1948.11 1245.95 1948.11M-122.186 1513.83V1775.94C-122.186 1871.03 -18.1703 1948.11 110.14 1948.11"
        stroke="#1B52AD"
        strokeWidth="2"
      />
    </svg>
  );
};
